/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssessmentDataModel } from '../models/assessment-data-model';
import { InquiryContactAttemptModel } from '../models/inquiry-contact-attempt-model';
import { InquiryModel } from '../models/inquiry-model';
import { ProcessResponse } from '../models/process-response';

@Injectable({
  providedIn: 'root',
})
export class InquiryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiInquiryGet
   */
  static readonly ApiInquiryGetPath = '/api/Inquiry';
  /**
   * Returns a list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryGet$Json$Response(): Observable<StrictHttpResponse<Array<InquiryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InquiryModel>>;
      })
    );
  }

  /**
   * Returns a list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryGet$Json(params?: {

  }): Observable<Array<InquiryModel>> {

    return this.apiInquiryGet$Json$Response().pipe(
      map((r: StrictHttpResponse<Array<InquiryModel>>) => r.body as Array<InquiryModel>)
    );
  }

  /**
   * Path part for operation apiInquiryPost
   */
  static readonly ApiInquiryPostPath = '/api/Inquiry';

  /**
   * Create a new inquiry record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryPost$Json$Response(params?: {
      body?: InquiryModel
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a new inquiry record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryPost$Json(params?: {
      body?: InquiryModel
  }): Observable<string> {

    return this.apiInquiryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiInquiryFilteredGet
   */
  static readonly ApiInquiryFilteredGetPath = '/api/Inquiry/filtered';

  /**
   * Returns a filtered list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryFilteredGet$Json$Response(params?: {
    language?: null | string;
    lastName?: null | string;
    firstName?: null | string;

    /**
     * callback or inquiry
     */
    dateType?: null | string;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * site name
     */
    site?: null | string;
    status?: null | string;
    contactType?: null | string;

  }): Observable<StrictHttpResponse<Array<InquiryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryFilteredGetPath, 'get');
    if (params) {

      rb.query('language', params.language, {});
      rb.query('lastName', params.lastName, {});
      rb.query('firstName', params.firstName, {});
      rb.query('dateType', params.dateType, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('site', params.site, {});
      rb.query('status', params.status, {});
      rb.query('contactType', params.contactType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InquiryModel>>;
      })
    );
  }

  /**
   * Returns a filtered list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryPaginatedGet$Json(params?: {
    pageSize: 15 | number;
    pageNumber: 1 | number;
    sortColumn: "lastName" | string;
    sortDirection: "asc" | string;
    searchStr: null | string;
  }): Observable<Array<InquiryModel>> {

    return this.apiInquiryPaginatedGet$Json$Response(params).pipe(
        map((r: StrictHttpResponse<Array<InquiryModel>>) => r.body as Array<InquiryModel>)
    );
  }

  /**
   * Path part for operation apiInquiryPaginatedGet
   */
  static readonly ApiInquiryPaginatedGetPath = '/api/Inquiry/paginated';

  /**
   * Returns a filtered list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryPaginatedGet$Json$Response(params?: {
    pageSize: 15 | number;
    pageNumber: 1 | number;
    sortColumn: "lastName" | string;
    sortDirection: "asc" | string;
    searchStr: null | string;

  }): Observable<StrictHttpResponse<Array<InquiryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryPaginatedGetPath, 'get');
    if (params) {

      rb.query('pageSize', params.pageSize, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('searchStr', params.searchStr, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<InquiryModel>>;
        })
    );
  }

  /**
   * Returns a filtered list of all, active, unlocked inquiry records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryFilteredGet$Json(params?: {
    language?: null | string;
    lastName?: null | string;
    firstName?: null | string;

    /**
     * callback or inquiry
     */
    dateType?: null | string;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * site name
     */
    site?: null | string;
    status?: null | string;
    contactType?: null | string;

  }): Observable<Array<InquiryModel>> {

    return this.apiInquiryFilteredGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InquiryModel>>) => r.body as Array<InquiryModel>)
    );
  }

  /**
   * Path part for operation apiInquiryGetNextGet
   */
  static readonly ApiInquiryGetNextGetPath = '/api/Inquiry/getNext';

  /**
   * Returns the next, active, unlocked inquiry record (and locks the record).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryGetNextGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryGetNextGet$Json$Response(params?: {
    currentInquiryKey: Number; 
    language?: null | string;
    lastName?: null | string;
    firstName?: null | string;
    dateType?: null | string;
    fromDate?: null | string;
    toDate?: null | string;
    site?: null | string;
    status?: null | string;

  }): Observable<StrictHttpResponse<InquiryModel>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryGetNextGetPath, 'get');
    if (params) {

      rb.query('currentInquiryKey', params.currentInquiryKey, {});
      rb.query('language', params.language, {});
      rb.query('lastName', params.lastName, {});
      rb.query('firstName', params.firstName, {});
      rb.query('dateType', params.dateType, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('site', params.site, {});
      rb.query('status', params.status, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InquiryModel>;
      })
    );
  }

  /**
   * Returns the next, active, unlocked inquiry record (and locks the record).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryGetNextGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryGetNextGet$Json(params?: {
    currentInquiryKey: Number;
    language?: null | string;
    lastName?: null | string;
    firstName?: null | string;
    dateType?: null | string;
    fromDate?: null | string;
    toDate?: null | string;
    site?: null | string;
    status?: null | string;

  }): Observable<InquiryModel> {

    return this.apiInquiryGetNextGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InquiryModel>) => r.body as InquiryModel)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyGet
   */
  static readonly ApiInquiryParentInquiryKeyGetPath = '/api/Inquiry/{parentInquiryKey}';

  /**
   * Returns a single inquiry record, the record will also be locked and assigned to the user requesting the record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyGet$Json$Response(params: {
    parentInquiryKey: number;

  }): Observable<StrictHttpResponse<InquiryModel>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyGetPath, 'get');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InquiryModel>;
      })
    );
  }

  /**
   * Returns a single inquiry record, the record will also be locked and assigned to the user requesting the record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyGet$Json(params: {
    parentInquiryKey: number;

  }): Observable<InquiryModel> {

    return this.apiInquiryParentInquiryKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InquiryModel>) => r.body as InquiryModel)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyContactAttemptGet
   */
  static readonly ApiInquiryParentInquiryKeyContactAttemptGetPath = '/api/Inquiry/{parentInquiryKey}/contactAttempt';

 

  /**
   * Get all contact attempts associated with a single inquiry record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyContactAttemptGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyContactAttemptGet$Json$Response(params: {
    parentInquiryKey: number;

  }): Observable<StrictHttpResponse<Array<InquiryContactAttemptModel>>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyContactAttemptGetPath, 'get');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InquiryContactAttemptModel>>;
      })
    );
  }

  /**
   * Get all contact attempts associated with a single inquiry record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyContactAttemptGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyContactAttemptGet$Json(params: {
    parentInquiryKey: number;

  }): Observable<Array<InquiryContactAttemptModel>> {

    return this.apiInquiryParentInquiryKeyContactAttemptGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InquiryContactAttemptModel>>) => r.body as Array<InquiryContactAttemptModel>)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyContactAttemptPost
   */
  static readonly ApiInquiryParentInquiryKeyContactAttemptPostPath = '/api/Inquiry/{parentInquiryKey}/contactAttempt';

  /**
   * Adds a new inquiry contact attempt record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyContactAttemptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryParentInquiryKeyContactAttemptPost$Json$Response(params: {
    parentInquiryKey: number;
      body?: InquiryContactAttemptModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyContactAttemptPostPath, 'post');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Adds a new inquiry contact attempt record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyContactAttemptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryParentInquiryKeyContactAttemptPost$Json(params: {
    parentInquiryKey: number;
      body?: InquiryContactAttemptModel
  }): Observable<ProcessResponse> {

    return this.apiInquiryParentInquiryKeyContactAttemptPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyAssessmentsGet
   */
  static readonly ApiInquiryParentInquiryKeyAssessmentsGetPath = '/api/Inquiry/{parentInquiryKey}/assessments';

  /**
   * Get all assessments associated with an inquiry record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyAssessmentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyAssessmentsGet$Json$Response(params: {
    parentInquiryKey: number;

  }): Observable<StrictHttpResponse<Array<AssessmentDataModel>>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyAssessmentsGetPath, 'get');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssessmentDataModel>>;
      })
    );
  }

  /**
   * Get all assessments associated with an inquiry record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyAssessmentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyAssessmentsGet$Json(params: {
    parentInquiryKey: number;

  }): Observable<Array<AssessmentDataModel>> {

    return this.apiInquiryParentInquiryKeyAssessmentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AssessmentDataModel>>) => r.body as Array<AssessmentDataModel>)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyAssessmentsPost
   */
  static readonly ApiInquiryParentInquiryKeyAssessmentsPostPath = '/api/Inquiry/{parentInquiryKey}/assessments';

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyAssessmentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryParentInquiryKeyAssessmentsPost$Json$Response(params: {
    parentInquiryKey: number;
      body?: AssessmentDataModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyAssessmentsPostPath, 'post');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyAssessmentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInquiryParentInquiryKeyAssessmentsPost$Json(params: {
    parentInquiryKey: number;
      body?: AssessmentDataModel
  }): Observable<ProcessResponse> {

    return this.apiInquiryParentInquiryKeyAssessmentsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiInquiryParentInquiryKeyUnlockPost
   */
  static readonly ApiInquiryParentInquiryKeyUnlockPostPath = '/api/Inquiry/{parentInquiryKey}/unlock';

  /**
   * Unlocks a single inquiry record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInquiryParentInquiryKeyUnlockPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyUnlockPost$Json$Response(params: {
    parentInquiryKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InquiryService.ApiInquiryParentInquiryKeyUnlockPostPath, 'post');
    if (params) {

      rb.path('parentInquiryKey', params.parentInquiryKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Unlocks a single inquiry record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInquiryParentInquiryKeyUnlockPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInquiryParentInquiryKeyUnlockPost$Json(params: {
    parentInquiryKey: number;

  }): Observable<ProcessResponse> {

    return this.apiInquiryParentInquiryKeyUnlockPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
